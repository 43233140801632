<template>
  <v-container class="general">
    <PageTitle :title="$t('trigger_list_page.title')" />
    <transition name="fade" mode="out-in">
      <v-layout
        v-if="!isLoading && !groupTriggers.length"
        class="row wrap text-center"
      >
        <v-flex class="xs12 sw-h5 font-weight-light">
          {{ $t("triggers_list_page.nothing_created") }}
        </v-flex>
      </v-layout>
    </transition>
    <v-card v-if="groupTriggers.length" class="px-4 py-3 border-radius">
      <v-layout
        v-for="(trigger, i) in groupTriggers"
        :key="`trigger-${i}`"
        class="row wrap"
      >
        <v-flex v-if="i !== 0" xs12>
          <v-divider></v-divider>
        </v-flex>
        <v-flex xs12 class="py-3">
          <v-layout row class="align-center">
            <v-flex xs12>
              <a @click.stop="editTrigger(trigger.id)">{{ trigger.name }}</a>
              <div class="sw-caption grey--text">
                {{ formatDate(trigger.scheduled_at) }}
              </div>
            </v-flex>
            <v-tooltip top>
              <v-btn
                slot="activator"
                icon
                class="ma-0"
                :loading="isVuexLoading(`trigger-${trigger.id}`)"
                @click="testTrigger(trigger.id)"
              >
                <font-awesome-icon class="sw-accent" icon="rocket" />
              </v-btn>
              <span>{{ $t("trigger_list_page.test_tooltip") }}</span>
            </v-tooltip>
            <v-tooltip top>
              <v-btn
                slot="activator"
                icon
                class="ma-0"
                :loading="isVuexLoading(`trigger-${trigger.id}`)"
                @click="deleteTrigger(trigger.id)"
              >
                <font-awesome-icon class="sw-accent" icon="trash" />
              </v-btn>
              <span>{{ $t("trigger_list_page.delete_tooltip") }}</span>
            </v-tooltip>
          </v-layout>
        </v-flex>
      </v-layout>
    </v-card>
    <div v-if="totalPages > 1" class="pt-4 text-center">
      <v-pagination
        v-model="page"
        :length="totalPages"
        :total-visible="totalVisible"
        @input="listGroupTriggers"
      />
    </div>
    <AppSpeedDial
      @click="$router.push({ name: 'triggers_create' })"
      :hint="$t('trigger_list_page.speed_dial_hint')"
    />
    <ConfirmationDialog ref="confirmationDialog" />
  </v-container>
</template>

<script>
import moment from "moment-timezone";

export default {
  data: () => ({
    isLoading: false,
    groupTriggers: [],
    page: 1,
    totalPages: null,
    totalVisible: 5,
  }),
  computed: {
    groupId() {
      return this.$route.params.group_id;
    },
    currentPage() {
      if (this.$route.query.page) {
        return Number(this.$route.query.page);
      }
      return 1;
    },
  },
  methods: {
    query(page) {
      this.$router
        .replace({
          query: Object.assign({}, this.$route.query, { page: page }),
        })
        .catch(() => {});
    },
    formatDate(date) {
      if (!date) return;
      return moment.tz(date, this.userTimezone).format("MMMM DD YYYY, H:mm");
    },
    listGroupTriggers() {
      if (!this.groupId) return;

      this.isLoading = true;

      const params = [
        this.groupId,
        {
          page: this.page,
          per_page: 15,
        },
      ];

      this.$api.groupTriggers.list(...params).then(
        (response) => {
          this.isLoading = false;

          if (!response) return;

          this.groupTriggers = response.data.data;
          this.totalPages = response.data.pagination.total_pages;
          this.query(this.page);
        },
        () => {
          this.isLoading = false;
        },
      );
    },
    editTrigger(id) {
      this.$router
        .push({
          name: "triggers_edit",
          params: {
            trigger_id: id,
          },
        })
        .catch(() => {});
    },
    async deleteTrigger(id) {
      const isConfirmed = await this.$refs.confirmationDialog.open(
        this.$t("trigger_list_page.delete_warning"),
        this.$t("common.no"),
        this.$t("common.yes"),
      );

      if (!isConfirmed) return;

      this.startVuexLoading(`trigger-${id}`);

      const params = [this.groupId, id];

      this.$api.groupTriggers.delete(...params).then(
        (response) => {
          this.endVuexLoading(`delete-trigger-${id}`);

          if (!response) return;

          this.$store.dispatch("addNotification", {
            message: this.$t("trigger_list_page.delete_success"),
          });

          this.page = 1;
          this.listGroupTriggers();
        },
        (error) => {
          this.endVuexLoading(`trigger-${id}`);
          this.errorMessageShow(error);
        },
      );
    },
    testTrigger(id) {
      this.startVuexLoading(`trigger-${id}`);

      const params = [this.groupId, id];

      this.$api.groupTriggers.test(...params).then(
        (response) => {
          this.endVuexLoading(`trigger-${id}`);

          if (!response) return;

          this.$store.dispatch("addNotification", {
            message: this.$t("trigger_list_page.test_success"),
          });
        },
        () => {
          this.endVuexLoading(`trigger-${id}`);
        },
      );
    },
  },
  watch: {
    groupId: {
      immediate: true,
      handler() {
        this.page = this.currentPage;
        this.listGroupTriggers();
      },
    },
  },
};
</script>

<style lang="scss"></style>
